.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

.slick-slide > div > img {
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  filter: brightness(0.7);
}
@media screen and (max-width: 600px) {
  .slick-slide > div > img {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    filter: brightness(0.7);
  }
}
